import angular from 'angular'
import 'angular-promise-tracker'
import _ from 'lodash'
import * as ConfigExperimentsAPI from '../../lib/config-experiments'
import * as Analytics from '../../lib/analytics'
import * as AuthServiceAPI from '../../lib/auth'
import Utils from '../../lib/utils'
import { downloadFile } from '../../lib/services/download-file'
import { ToggleModel } from '../../lib/model/model-toggle'
import { GridItemCellRendererFactory, GridInfoCellRendererFactory } from './components/grid-cell-renderer'
import { GridOverlayMessageRendererFactory } from '../../components/grid-overlay-message-renderer'
import { SidebarModel } from '../../components/sidebar'
import { ActionsPanelSelectItem, ActionsPanelButtonItem, ActionsPanelModel, ActionsPanelSimpleItem, ActionsCustomMetricTreeModel} from '../../components/actions-panel'
import { isObject } from '../../lib/utils'
import { getOrganization } from '../../lib/auth'
import { GridPageViewDataSchema, GridPageViewMetaSchema } from './item-action-model-state-validation'
import { QueryServiceExport } from '../../modules/services/query-service-export'
import { getNumberOfPagesEstimate, ITEM_EXPORT_OPTIONS } from './grid-page-export-page-count-estimator'

###*
@typedef {import('../../components/drag-and-drop').DragAndDropExternalAPI} DragAndDropExternalAPI
@typedef {import('../../lib/types').IConfigObj} IConfigObj
@typedef {import('../../lib/types').IMetricDefinition} IMetricDefinition
@typedef {import('../../lib/config-hierarchy').IPropertyDefinition} IPropertyDefinition
@typedef {import('../../modules/hierarchy/hierarchy.module').IHierarchyService} IHierarchyService
@typedef {import('../main-controller').IHourPropertyService} IHourPropertyService
@typedef {import('../main-controller').IQueryMetrics} IQueryMetrics
@typedef {import('../main-controller').DashboardRootScope} DashboardRootScope
@typedef {import('../../directives/outside-element-click.directive').IOutsideElementClick} IOutsideElementClick
@typedef {import('./components/grid-cell-renderer').GridActionsModel} GridActionsModel
@typedef {InstanceType<GridActionsModel>} IGridActionsModel
@typedef {import('./components/grid-cell-renderer').IGridPageCellRenderer} IGridPageCellRenderer
@typedef {import('../../lib/angular').AngularInjected<typeof ItemGridModel>} _ItemGridModel
@typedef {ReturnType<_ItemGridModel>} IItemGridModel
@typedef {import('../../lib/angular').AngularInjected<typeof _ItemActionModelList>} ItemActionModelList
@typedef {InstanceType<ItemActionModelList>} IItemActionModelList
@typedef {import('../../lib/angular').AngularInjected<typeof _ItemActionsModelState>} ItemActionsModelStateService
###

module = angular.module '42.controllers.items', []
module.config ($routeProvider, ROUTES, CONFIG) ->
    override = _.pick(CONFIG.routes?.grid or CONFIG.routes?.items or {}, 'label', 'url')
    route = {...ROUTES.grid, ...override}
    $routeProvider.when(route.oldUrl, {redirectTo:route.url}) if route.oldUrl
    $routeProvider.when(route.url, route)


module.controller 'ItemsController', ['$q','$rootScope','promiseTracker','ItemViewState','$scope'
###*
@typedef {unknown} ItemViewStateActionModel
@param {angular.IQService} $q
@param {DashboardRootScope} $rootScope
@param {angular.promisetracker.PromiseTrackerService} promiseTracker
@param {{fetch: () => angular.IPromise<{actionsModel: ItemViewStateActionModel}>}} ItemViewState
@param {angular.IScope & {
    itemViewModel: unknown;
    viewItemsPromiseTracker: angular.promisetracker.PromiseTracker;
    model: null | ItemViewStateActionModel;
    loaded: boolean;
    organizationId: string;
    experiments?: unknown;
}} $scope
###
($q, $rootScope, promiseTracker, ItemViewState, $scope) ->
    $scope.itemViewModel = {}
    $scope.loaded = true

    ###* @type {null | angular.IDeferred<void>} ###
    deferred = $q.defer()
    $scope.viewItemsPromiseTracker = promiseTracker()
    $scope.viewItemsPromiseTracker.addPromise(deferred.promise)
    $scope.model = null

    ###* @type {(() => void)[]} ###
    watchers = []
    ###* @returns {void} ###
    init = ->
        watchers.forEach((x) -> x())
        watchers = []
        watchers.push $rootScope.$on 'query.refresh', -> init()
        promise = $q.all([
            ItemViewState.fetch()
            getOrganization()
            ConfigExperimentsAPI.fetch()
        ]).then ([{actionsModel}, organizationId, experiments]) ->
            deferred?.resolve()
            deferred = null
            $scope.model = actionsModel
            $scope.organizationId = organizationId
            # TODO:
            # - Used as Toggle for `sortToggleInGridPage` feature.
            # - Used as Toggle for `metricsCategorization` feature.
            $scope.experiments = experiments
        .catch (error) ->
            console.error("Items page state fetch error:", error)
            deferred?.reject(error)
            $scope.viewItemsPromiseTracker.addPromise(promise)
            return
        return

    cleanupInitializedWatcher = $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        return init()

    $scope.$on '$destroy', ->
        cleanupInitializedWatcher()
        watchers.forEach (x) -> x()
]

module.service 'ItemGridQuery', ['QueryServiceAPI', (QueryServiceAPI) ->
    fetch: (query) ->
        query = _.cloneDeep(query or {})
        throw new Error("Missing required `query.options.groupBy` property.") if not query.options?.groupBy
        return QueryServiceAPI().then (api) -> api.query.topItems(query)
]

module.constant 'METRIC_RESTRICTIONS',
    improved_aps:
        tables: ['transaction_items']
    sellthru_percentage:
        tables: ['transaction_items']


_ItemActionsModelState = ['$q','CONFIG','ItemActionModelStateAPI','Hierarchy','HourProperty','QueryMetrics',
###*
@param {angular.IQService} $q
@param {IConfigObj} CONFIG
@param {any} ItemActionModelStateAPI
@param {IHierarchyService} Hierarchy
@param {IHourPropertyService} HourProperty
@param {IQueryMetrics} QueryMetrics
###
($q, CONFIG, ItemActionModelStateAPI, Hierarchy, HourProperty, QueryMetrics) ->

    fetchState: () ->
        ItemActionModelStateAPI.get()
        .then (state) ->
            return state if _.isArray(state?.available)
            return {selected:state?.id, available:[state]}
        .then (state) =>
            state.available = _.compact(state.available)
            return state if state.available.length > 0
            return @createState().then (x) ->
                if state.available.length is 0
                    state.available.push(x)
                else
                    state.available.unshift(x)
                    state.selected = state.available[0]
                return state
        .catch (error) ->
            # FIXME: Dangerous! Could clear all views...
            console.error("Could not load item action model state:")
            console.error(error)
            Analytics.logError(error)
            return null

    fetchHierarchy: ->
        $q.all([
            Hierarchy.fetch()
            HourProperty.fetch()
        ]).then ([hierarchy, hourProperty]) ->
            hierarchy.groupBy.push(hourProperty) if hourProperty
            return hierarchy

    fetchMetrics: -> QueryMetrics.fetch().then (metrics) ->
        throw new Error("[grid] QueryMetrics.fetch() did not return an array") if not Array.isArray(metrics)
        throw new Error("[grid] QueryMetrics.fetch() returned an empty array") if metrics.length is 0
        metrics.forEach (metric) ->
            # Frye Request: We round up all percentage cells.
            if metric.cellFilter and metric.cellFilter.indexOf('percent:') is 0 and metric.cellFilter.split(':').length is 3
                metric.cellFilter = "#{metric.cellFilter}:0"
        return metrics

    ###* @type {null | angular.IPromise<string>} ###
    getDefaultMetricsPromise: null
    getDefaultMetrics: () ->
        DEFAULT_METRICS = [
            "demand_gross_sales",
            "demand_net_sales",
            "demand_gross_markdown_value",
            "demand_gross_sales_margin",
            "on_hands_units",
            "demand_sellthru_percentage"
        ]
        return @getDefaultMetricsPromise ?= $q.when do =>
            return CONFIG.defaults?.items?.metrics if CONFIG.defaults?.items?.metrics
            return @fetchMetrics().then (metrics) ->
                metricsByField = _.keyBy(metrics, 'field')
                demandMetricsToValidate = DEFAULT_METRICS.slice(0, 2)
                isDemandMetricsValid = demandMetricsToValidate.every((field) -> Boolean(metricsByField[field]))
                return DEFAULT_METRICS if isDemandMetricsValid
                return DEFAULT_METRICS.map((x) -> x.replace(/^demand_/, ''))

    fetchValues: -> $q.all([@fetchHierarchy(), @fetchMetrics()]).then ([{groupBy}, metrics]) ->

        hierarchy =
            groupBy      : groupBy.map((x) -> {...x, group: x.category?.label ? 'Uncategorized'})
            itemsGroupBy : groupBy.map((x) -> {...x, group: x.category?.label ? 'Uncategorized'})

        hierarchyIndex = Object.keys(hierarchy).reduce ((result, key) ->
            result[key] = {}
            hierarchy[key].forEach((x) -> result[key][x.id] = x)
            return result
        ), {}

        itemsSortBy = _.compact metrics.map (metric) ->
            return if metric.field.startsWith('growth')
            return if not metric.headerGroup
            id:    metric.field
            group: metric.category ? 'Uncategorized'
            label: do ->
                label = _.compact([metric.headerGroup, metric.headerName]).join(" ")
                return (label or "").trim()

        metrics: metrics
        metricsByField: _.keyBy(metrics, 'field')
        hierarchyIndex: hierarchyIndex
        groupBy: hierarchy.groupBy
        itemsGroupBy: hierarchy.itemsGroupBy
        itemsLimitBy: [5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250]
        itemsSortBy: itemsSortBy
        itemsSortOrder: [
            { id:  1, label: 'ASC' }
            { id: -1, label: 'DESC' }
        ]

    ###* @param {{selected: IGridActionsModel, available: IGridActionsModel[]}} model ###
    save: (model) ->
        console.log("[grid] saving state...")
        states = @serializeStates(model)
        return ItemActionModelStateAPI.put(states).catch (error) ->
            error = new Error("[grid][ItemActionsModelState][save] Could not save item action model state...\n#{error?.message}")
            Analytics.logError(error)
            return

    ###* @param {{selected: IGridActionsModel, available: IGridActionsModel[]}} model ###
    serializeStates: (model) ->
        selected:  model.selected?.id
        available: model.available.map (x) => @serializeState(x)

    ###* @param {IGridActionsModel} model ###
    serializeState: (model) ->
        views =
            panel: model.views.panel.serialize()['isOpen']
            images: model.views.images.serialize()['isOpen']
        selected =
            metrics:         model.selected.metrics
            groupBy:         model.selected.groupBy.id
            itemsGroupBy:    model.selected.itemsGroupBy.id
            itemsSortBy:     model.selected.itemsSortBy.id
            itemsLimitBy:    model.selected.itemsLimitBy
            itemsSortOrder:  model.selected.itemsSortOrder
        return {id:model.id, name:model.name, views, selected}

    fetch: () -> $q.all([@fetchValues(), @fetchState(), @getDefaultMetrics()]).then ([values, state, defaultMetrics]) =>
        state.available = state.available.map (x) => @normalizeState(values, x, defaultMetrics)
        state.selected = _.find state.available, (x) -> x.id is state.selected
        state.selected ?= state.available[0]
        return state

    createState: (state) -> $q.all([@fetchValues(), @getDefaultMetrics()]).then ([values, defaultMetrics]) =>
        return @normalizeState(values, state ? {}, defaultMetrics)

    duplicate: (item) ->
        $q.all([@fetchValues(), @getDefaultMetrics()]).then ([values, defaultMetrics]) =>
            newItem = @serializeState(item)
            newItem.id = undefined
            return @normalizeState(values, newItem, defaultMetrics)

    normalizeState: (values, state, defaultMetrics) ->
        state = {selected:state} if not state?.views

        state ?= {}
        state.selected ?= {}
        state.views ?= {}
        delete state.views.metrics

        ignoreError = (fn) ->
            try return fn()
            catch error
                try Analytics.logError(error)
                return null

        id: state.id or Utils.uuid()
        name: state.name or "New View"
        views:
            panel: ToggleModel.Parse(state.views.panel)
            images: ToggleModel.Parse(state.views.images)
        values: values
        selected:
            metrics: ignoreError ->
                state.selected.metrics ?= defaultMetrics
                state.selected.metrics = (state.selected.metrics or []).map (x) -> x.field or x
                availableMetrics = values.metrics.map (x) -> x.field
                return state.selected.metrics.filter (x) -> availableMetrics.includes(x)
            groupBy: ignoreError ->
                state.selected.groupBy ?= CONFIG.defaults?.items?.groupBy
                state.selected.groupBy = state.selected.groupBy?.id or state.selected.groupBy
                return values.groupBy[0] if not state.selected.groupBy
                return values.hierarchyIndex.groupBy[state.selected.groupBy]
            itemsGroupBy: ignoreError ->
                state.selected.itemsGroupBy ?= CONFIG.defaults?.items?.itemsGroupBy
                state.selected.itemsGroupBy = state.selected.itemsGroupBy?.id or state.selected.itemsGroupBy
                return values.hierarchyIndex.itemsGroupBy[state.selected.itemsGroupBy]
            itemsSortBy: ignoreError ->
                state.selected.itemsSortBy ?= CONFIG.defaults?.items?.itemsSortBy
                state.selected.itemsSortBy = state.selected.itemsSortBy?.id or state.selected.itemsSortBy
                return _.find values.itemsSortBy, (x) -> state.selected.itemsSortBy is x.id
            itemsLimitBy: ignoreError ->
                state.selected.itemsLimitBy ?= CONFIG.defaults?.items?.itemsLimitBy
                return state.selected.itemsLimitBy if values.itemsLimitBy.includes(state.selected.itemsLimitBy)
                return 10
            itemsImageSize: ignoreError ->
                state.selected.itemsImageSize ?= CONFIG.defaults?.items?.itemsImageSize ? null
                return state.selected.itemsImageSize
            itemsSortOrder: ignoreError ->
                value = state.selected.itemsSortOrder
                return value if [-1, 1].includes(parseInt(value))
                return -1
]
module.service('ItemActionsModelState', _ItemActionsModelState)



module.service 'ItemActionModelStateAPI', (Utils, StorageAPI) ->

    getKey = -> AuthServiceAPI.getOrganization().then (organization) ->
        prefix = "items.action-model-state"
         # HACK: We need to migrate user configs of all orgs.. doing it for Ippolita Wholesale in the meantime
         #       since they are complaining about hierarchy overwriting their views.
        return "#{prefix}-v2" if not _.startsWith(organization, 'ippolita_wholesale')
        return "#{prefix}-v3"

    getStorageAPI = ->
        return getKey().then((key) -> StorageAPI(key))

    get: ->
        return getStorageAPI().then (api) -> api.get()

    put: (data) ->
        data = Utils.copy(data)
        return getStorageAPI().then (api) -> api.put(data)

module.service 'ItemViewState', ['ItemActionModelList', 'ItemActionsModelState',
###*
@param {ItemActionModelList} ItemActionModelList
@param {ItemActionsModelStateService} ItemActionsModelState
###
(ItemActionModelList, ItemActionsModelState) ->
    fetch: -> ItemActionsModelState.fetch().then((x) -> {actionsModel: new ItemActionModelList(x)})
]

module.directive 'itemView', ['ItemActionModelList',
###*
@param {ItemActionModelList} ItemActionModelList
@returns {angular.IDirective<angular.IScope & DragAndDropExternalAPI & {
    model: IItemActionModelList;
    experiments: Record<string, undefined | boolean>;
    organizationId: string;
    addNewView: () => void;
    removeTab: (tabId: string) => void;
    exportTab: IItemActionModelList['exportTab'];
    openTabImportPopup: (event: Event) => boolean;
}>}
###
(ItemActionModelList) ->
    restrict: 'E'
    scope:
        model: '='
        experiments: '='
        organizationId: '='
    replace: true
    template: \
    """
    <article class="view view-items">
        <header>
            <tabs-with-menu
                tabs="model.available"
                selected="model.selected"
                added="addNewView"
                removed="model.remove"
                dragged="model.reorder"
                duplicated="model.duplicate"
                shared="exportTab"
                imported="openTabImportPopup"
            >
            </tabs-with-menu>
        </header>
        <main class="view-items-body" drag-and-drop-zone>
            <item-view-container model="model" experiments="experiments"></item-view-container>
        </main>
    </article>
    """
    link: (scope) ->
        scope.addNewView = ->
            scope.model.add()
            Analytics.track(Analytics.EVENTS.USER_CREATE_VIEW_GRID)

        scope.removeTab = (tabId )->
            scope.model.remove(tabId) if window.confirm("""
                Are you sure you want to delete the View - "#{scope.model.selected.name}" ?\nThis action cannot be un-done.
            """)

        dnd = scope.fillDragAndDropZoneExternalAPI
            onFile: (file) ->
                data = ItemActionModelList.ValidateViewConfigFile(file, scope.organizationId)
                scope.model.createTabFromJSON(data)
                Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB, {view: data})
            onError: (error) ->
                Analytics.track(Analytics.EVENTS.USER_IMPORT_GRID_TAB_FAILED, {error})

        scope.exportTab = (...args) ->
            scope.model.exportTab(...args)

        scope.openTabImportPopup = ($event) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            dnd.openUploadPopup()
            return true
]



module.directive 'itemViewContainer', ['$q','$timeout','ItemGridQuery','OutsideElementClick',
###*
@param {angular.IQService} $q
@param {angular.ITimeoutService} $timeout
@param {{fetch: (query: unknown) => angular.IPromise<unknown>}} ItemGridQuery
@param {IOutsideElementClick} OutsideElementClick
@returns {angular.IDirective<angular.IScope & {
    initialized: boolean;
    gridPageModel?: {
        toggleOffAllEditModes: (exclude?: unknown) => void;
    };
    actionsModel?: IItemActionModel;
    actionsPanelModel: ActionsPanelModel<unknown>;
    sidebarModel?: null | SidebarModel;
    toggleSidebar?: (event: Event) => void;
    experiments?: Record<string, undefined | boolean>;
    openPanel: () => void;
    isSidebarDisabled?: boolean;
    export: () => void;
    pageCount?: undefined | null | string | number;
    disableExportButton?: boolean;
    exportButtonText?: null | string;
    forceMetricsInSidebar?: boolean;
    pebblesGroupByModel?: unknown;
}>}
###
($q, $timeout, ItemGridQuery, OutsideElementClick) ->
    restrict: 'E'
    scope:
        gridPageModel: '=model'
        experiments: '='
    replace: true
    template: \
    """
    <article class="view-container view-items-container notransition"
        ng-class="{'initializing': !initialized, 'hide': !actionsModel.views.panel.isActive}">
        <header class="action-header">
            <div class="action-header-actions-panel-options">
                <div class="left-side" ng-if="pebblesGroupByModel">
                    <properties-items model="pebblesGroupByModel"></properties-items>
                </div>
            </div>
        </header>
        <main>
            <article class="grid-bar" ng-if="isSidebarDisabled">
                <item-natural-language-query model="actionsModel"></item-natural-language-query>
                <button-export
                    on-click="export()"
                    text="exportButtonText"
                    disable="disableExportButton"
                    ng-if="pageCount && pageCount > 0">
                </button-export>
                <button
                    class="button-toggle-actions-panel button-toggle-actions-panel-show"
                    ng-click="openPanel()">
                    <span>Show Panel</span>
                    <i class="icon-down-open"></i>
                </button>
            </article>
            <div class="metrics-funnel-breadcrumb-actions-panel">
                <div class="left-panel" ng-if="!isSidebarDisabled">
                    <div class="sidebar-header-close"
                        ng-class="{'closed': !sidebarModel.toggle.isActive}"
                        ng-click="toggleSidebar($event)">
                        <div class="sidebar-toggle-icon">
                            <i class="icon-left-open"></i>
                        </div>
                    </div>
                </div>
                <actions-panel ng-if="actionsPanelModel.items.length > 0" model="actionsPanelModel"></actions-panel>
            </div>
            <div class="main-part">
                <sidebar ng-if="!isSidebarDisabled" model="sidebarModel"></sidebar>
                <div class="main-body-part">
                    <article class="grid-bar" ng-if="!isSidebarDisabled">
                        <item-natural-language-query model="actionsModel"></item-natural-language-query>
                        <button-export
                            on-click="export()"
                            text="exportButtonText"
                            disable="disableExportButton"
                            ng-if="pageCount && pageCount > 0">
                        </button-export>
                    </article>
                    <item-grid model="actionsModel" page-count="pageCount"></item-grid>
                </div>
            </div>
        </main>
    </article>
    """
    link: (scope, element) ->
        $element = $(element)
        $header = $element.find('> header')

        OutsideElementClick scope, $element.find('.ui-tabs, .ui-tab'), ->
            scope.gridPageModel?.toggleOffAllEditModes()
            return

        ###* @param {undefined | boolean} [active] ###
        scope.initialized = false

        updatePanel = (active = true) ->
            $element.css({ top: '0px' }) if active
            $element.css({ top: -($header.height() ? 0) }) if not active

        ###* @type {null | angular.IPromise<void>} ###
        transitionEnableTimeout = null
        transitionEnableTimeoutCB = () ->
            $timeout.cancel(transitionEnableTimeout) if transitionEnableTimeout
            $element[0]?.classList.remove('notransition')
            transitionEnableTimeout = $timeout((-> $element[0]?.classList.add('notransition')), 3000)

        scope.openPanel = ->
            transitionEnableTimeoutCB()
            scope.actionsModel?.views.panel.open()
            scope.actionsModel?.save()
            return

        scope.export = ->
            return if not scope.actionsModel
            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_GRID)
            if typeof scope.pageCount is 'number' and scope.pageCount >= ITEM_EXPORT_OPTIONS.maxPages
                alert \
                """
                Sorry, but you're trying to export too much data...
                This would generate a #{scope.pageCount} page PDF!

                Use the filters to reduce the data size, and try the export again.
                """
                return $q.reject()
            scope.actionsModel.toExportQuery()
            .then((query) -> ItemGridQuery.fetch(query))
            .then(QueryServiceExport.downloadAs('items-export.pdf'))

        scope.$watch 'pageCount', (pageCount) ->
            scope.disableExportButton = pageCount >= ITEM_EXPORT_OPTIONS.maxPages
            scope.exportButtonText = do ->
                return null if typeof pageCount isnt 'number' or pageCount <= 0
                return "export (#{pageCount} page)" if pageCount is 1
                return "export (#{pageCount} pages)"

        scope.$watch 'actionsModel.selected.itemsGroupBy.id', ->
            scope.actionsModel?.updateExtraItemInfo()

        scope.$watch 'gridPageModel.selected', (model) ->
            return if not model
            scope.actionsModel = model
            scope.gridPageModel?.toggleOffAllEditModes(model)

        unWatchers = []

        scope.toggleSidebar = (event) ->
            return if not scope.sidebarModel
            isActive = scope.sidebarModel.toggle.isActive
            return scope.sidebarModel.toggle.open(event, 'properties') if not isActive
            return scope.sidebarModel.toggle.close(event)

        # FIXME: embed experiments in the model or something...
        ###* @param {undefined | IGridActionsModel} model ###
        updateFromActionModel = (model) ->
            unWatchers.forEach((unWatch) -> unWatch?())
            unWatchers = []
            return if not model

            experiments = (scope.experiments ? {})
            {sidebar, metricsCategorization} = experiments
            sidebar ?= false

            scope.isSidebarDisabled = not sidebar
            # In the Grid Page it's not possible to have groupByPropertiesInSidebar and NOT metricsInSidebar at the same time
            scope.forceMetricsInSidebar = sidebar

            if scope.isSidebarDisabled
                scope.pebblesGroupByModel =
                    label: 'Group By',
                    available: model.values.groupBy
                    selected: model.selected.groupBy
                    onClick: (property) ->
                        model.selected.groupBy = property[0]
                        return

            sidebarModel = do ->
                return null if scope.isSidebarDisabled
                return new SidebarModel({
                    options: hideTabs: true
                    properties:
                        selected: do ->
                            return model.selected.groupBy if Array.isArray(model.selected.groupBy)
                            return [model.selected.groupBy]
                        available: model.values.groupBy
                        selectProperty: (properties) ->
                            return if not properties[0]
                            model.selected.groupBy = properties[0]
                            return
                    displayBy:
                        available: model.values.itemsGroupBy
                        selected: do ->
                            return model.selected.itemsGroupBy if Array.isArray(model.selected.itemsGroupBy)
                            return [model.selected.itemsGroupBy]
                        selectProperty: (item) ->
                            model.selected.itemsGroupBy = item[0]
                            return
                    metrics:
                        selected: model.selected.metrics
                        available: model.values.metrics
                        options: { addAllMetrics: false, hideCategories: not metricsCategorization }
                        selectMetrics: (metrics) ->
                            model.setSelectedMetrics(metrics)
                            # FIXME: same array object used for both models...
                            sidebarModel.metrics.selected = model.selected.metrics if sidebarModel and sidebarModel.metrics
                            return
                    toggle: scope.sidebarModel?.toggle
                })
            scope.sidebarModel = sidebarModel

            scope.actionsPanelModel = do ->
                ###* @type {ActionsPanelModel['items']} ###
                actionsPanelModel = []

                if sidebarModel
                    unWatchers.push do (sidebarModel) ->
                        groupByActionItemModel = new ActionsPanelButtonItem({
                            label: 'Group By',
                            selected: model.selected.groupBy.label
                            cssClass: 'sidebar-toggle'
                            icon:
                                type: 'icon-down-open'
                            isActive: ->
                                sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'properties'
                            onClick: ($event) ->
                                $event.preventDefault()
                                $event.stopImmediatePropagation()
                                sidebarModel.toggle.toggle($event, 'properties')
                                return
                        })
                        actionsPanelModel.push(groupByActionItemModel)
                        return scope.$watch 'actionsModel.selected.groupBy', (groupBy) ->
                            groupByActionItemModel.selected = groupBy.label
                            return

                    unWatchers.push do (sidebarModel) ->
                        displayByActionItemModel = new ActionsPanelButtonItem({
                            label: 'Display By',
                            selected: model.selected.itemsGroupBy.label
                            cssClass: 'sidebar-toggle'
                            icon:
                                type: 'icon-down-open'
                            isActive: -> sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'displayBy'
                            onClick: ($event) ->
                                $event.preventDefault()
                                $event.stopImmediatePropagation()
                                sidebarModel.toggle.toggle($event, 'displayBy')
                                return
                        })
                        actionsPanelModel.push(displayByActionItemModel)
                        return scope.$watch 'actionsModel.selected.itemsGroupBy', (itemsGroupBy) ->
                            displayByActionItemModel.selected = itemsGroupBy.label
                            return

                if scope.isSidebarDisabled
                    actionsPanelModel.push(new ActionsPanelSelectItem({
                        label: 'Display By',
                        available: model.values.itemsGroupBy
                        selected: model.selected.itemsGroupBy
                        icon:
                            type: 'icon-down-open'
                        onClick: (item) ->
                            model.selected.itemsGroupBy = item
                            return
                    }))

                actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                    label: 'Sort By',
                    available: model.values.itemsSortBy
                    selected: model.selected.itemsSortBy
                    icon:
                        type: 'icon-down-open'
                    onClick: (item) ->
                        model.selected.itemsSortBy = item
                        return

                if scope.experiments?.sortToggleInGridPage
                    actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                        label: 'Sort Order',
                        available: model.values.itemsSortOrder
                        selected: model.values.itemsSortOrder.find((x) -> x.id is model.selected.itemsSortOrder)
                        icon:
                            type: 'icon-down-open'
                        onClick: (item) ->
                            model.selected.itemsSortOrder = item.id
                            return

                actionsPanelModel.push new ActionsPanelSelectItem do (model) ->
                    itemsLimitBy = model.values.itemsLimitBy.map((limit) -> { label: limit })
                    label: 'Limit',
                    available: itemsLimitBy
                    selected: itemsLimitBy.find((limit) -> limit.label is model.selected.itemsLimitBy)
                    icon:
                        type: 'icon-down-open'
                    onClick: (item) ->
                        model.selected.itemsLimitBy = item.label
                        return

                if not scope.isSidebarDisabled
                    actionsPanelModel.push new ActionsPanelSimpleItem do (sidebarModel) ->
                        label: 'Edit Metrics',
                        icon:
                            type: 'icon-flow-cascade'
                        cssClass: 'sidebar-toggle'
                        isActive: ->
                            return false if not sidebarModel
                            return sidebarModel.toggle.isActive and sidebarModel.toggle.tab is 'metrics'
                        onClick: ($event) ->
                            $event.preventDefault()
                            $event.stopImmediatePropagation()
                            sidebarModel?.toggle.toggle($event, 'metrics')
                            return
                else
                    metricsActionItemModel = new ActionsCustomMetricTreeModel do (model) ->
                        selected: model.selected.metrics
                        available: model.values.metrics
                        options: { addAllMetrics: false, hideCategories: not metricsCategorization }
                        onChange: (metrics) ->
                            # FIXME: monkey patching the model...?
                            model.setSelectedMetrics(metrics)
                            metricsActionItemModel.selected = model.selected.metrics
                            return
                    actionsPanelModel.push(metricsActionItemModel)

                unWatchers.push do ->
                    ###* @param {undefined | {isOpen:boolean}} state ###
                    getLabel = (state) ->
                        return 'Hide Images' if (state?.isOpen ? true)
                        return 'Show Images'
                    imagesToggleItemModel = new ActionsPanelSimpleItem do (model) ->
                        secondary: true,
                        label: getLabel(model.views.images.state)
                        cssClass: 'reverse'
                        icon:
                            type: 'icon-picture'
                        isActive: ->
                            return model.views.images.state.isOpen ? true
                        onClick: ($event) ->
                            $event.preventDefault()
                            $event.stopImmediatePropagation()
                            model.views.images.toggle()
                            return
                    actionsPanelModel.push(imagesToggleItemModel)
                    return scope.$watch 'actionsModel.views.images.state', (state) ->
                        imagesToggleItemModel.label = getLabel(state)
                        return

                if scope.isSidebarDisabled
                    unWatchers.push do ->
                        hidePanelItemModel = new ActionsPanelSimpleItem({
                            secondary: true,
                            label: 'Hide Panel'
                            cssClass: do ->
                                return 'hide' if model.views.panel.isActive is false
                                return ''
                            icon:
                                type: 'icon-up-open'
                            onClick: ($event) ->
                                $event?.preventDefault()
                                $event?.stopImmediatePropagation()
                                transitionEnableTimeoutCB()
                                model.views.panel.close()
                                model.save()
                                return
                        })
                        actionsPanelModel.push(hidePanelItemModel)
                        return scope.$watch 'actionsModel.views.panel.state', (state) ->
                            hidePanelItemModel.cssClass = do ->
                                return 'hide' if state?.isOpen is false
                                return ''
                            return

                return new ActionsPanelModel({items: actionsPanelModel})

        scope.$watch('actionsModel', (model) -> updateFromActionModel(model))
        scope.$watch 'actionsModel.views.panel.isActive', (active) ->
            $timeout (->
                scope.initialized = true
                $timeout (-> updatePanel(active)), 0
            ), 0
            return

        resizeObserver = new ResizeObserver (-> updatePanel(scope.actionsModel?.views?.panel.isActive ? false))
        resizeObserver.observe($element[0])
        scope.$on('$destroy', -> resizeObserver.disconnect())

        return
]

class ItemGridDataViewModel

    ###* @param {IGridActionsModel} model ###
    constructor: (model, ItemGridQuery) ->
        @ItemGridQuery = ItemGridQuery
        ###* @type {null | {items: Record<string, unknown>[]}[]} ###
        @data = null
        @init(model)

    fetch: (model) ->
        model.toQuery()
        .then((query) => @ItemGridQuery.fetch(query))

    init: (model) ->
        @fetch(model).then (data) => @data = data

###* @typedef {InstanceType<typeof ItemGridDataViewModel>} IItemGridDataViewModel ###

module.directive 'itemGrid', ['$timeout','$rootScope','ItemGridQuery','ItemGridModel','METRIC_RESTRICTIONS',
###*
@param {angular.ITimeoutService} $timeout
@param {DashboardRootScope} $rootScope
@param {unknown} ItemGridQuery
@param {_ItemGridModel} ItemGridModel
@returns {angular.IDirective<angular.IScope & {
    model?: IItemActionModel;
    grid: any;
    pageCount: undefined | null | string | number;
    gridDataModel: unknown;
    view: {
        rowsData: unknown;
        selectedMetrics: unknown[];
    };
}>}
###
($timeout, $rootScope, ItemGridQuery, ItemGridModel, METRIC_RESTRICTIONS) ->
    restrict: 'E'
    scope:
        model:     '='
        pageCount: '='
    replace: true
    template: \
    """
    <article class="item-grid">
        <div class="grid-container" ng-if="grid.options">
            <div ag-grid="grid.options" class="ag-42 grid grid-new ag-theme-alpine" ng-class="{'grid-no-filter':!grid.options.enableFilter}"></div>
        </div>
    </article>
    """
    link: (scope, element) ->
        scope.grid = new ItemGridModel()
        scope.pageCount = undefined
        scope.view = {
            rowsData: undefined
            selectedMetrics: []
        }

        restrictItemsSortBy = ->
            selected = scope.model.selected
            restriction = METRIC_RESTRICTIONS[selected.itemsSortBy.id]
            return if not restriction
            if _.includes(restriction.tables, selected.groupBy.table) or _.includes(restriction.tables, selected.itemsGroupBy.table)
                selected.itemsSortBy = scope.model.values.itemsSortBy[0]
            return

        ###*
        @param {IItemActionModel} model
        @param {Record<string, unknown>[]} data
        @returns {number}
        ###
        getPageCountEstimate = (model, data) ->
            return 0 if data.length is 0
            return -1 if not model.selected
            metrics = scope.view.selectedMetrics.map((metric) -> model.values.metricsByField[metric])
            pageCount = getNumberOfPagesEstimate({
                metrics, data,
                itemsExtraInfo: model.selected.itemsExtraInfo,
                itemsGroupBy: model?.selected.itemsGroupBy,
                columnDefs: scope.grid?.columnDefs,
            })
            hasFilters = do ->
                queryFilters = $rootScope.query?.filters ? {}
                return Object.keys(queryFilters).filter((x) -> x isnt 'transactions').length > 0
            return pageCount + 1 if hasFilters
            return pageCount

        unWatchGridUpdate = null
        updateGridRowHeight = (options) ->
            unWatchGridUpdate?()
            return if not element[0]

            ## Wait for the table to render columns and rows
            unWatchGridUpdate = scope.$watch((->
                return true if not scope.grid.data
                return Boolean(element[0]?.getElementsByClassName('item-grid-cell-value')[0]?.getElementsByClassName('item-info'))
            ), ((isItemInfoDisplayedVisible) ->
                if isItemInfoDisplayedVisible

                    itemInfoHeight = do ->
                        itemInfoEl = element[0]?.querySelector('.item-grid-cell-value .item-info')
                        return 115 if not itemInfoEl
                        return $(itemInfoEl).height() or 115

                    itemImageHeight = do ->
                        imagesEnabled = scope.model.views.images.isActive
                        return 75 if not imagesEnabled
                        configuredImageSize = scope.model.selected?.itemsImageSize ? 0
                        hasImagesInData = (scope.grid.data or []).find((item) -> item.items?.find((rowItem) -> rowItem.item_image))
                        return Math.max(130, configuredImageSize) if hasImagesInData
                        ## Fallback
                        imageEl = element[0]?.querySelector('.item-grid-cell-value .item-image')
                        hasBlankClass = imageEl?.classList.contains('blank')
                        return 75 if not imageEl or hasBlankClass
                        return Math.max(130, configuredImageSize)

                    cellHeight = itemInfoHeight + itemImageHeight
                    scope.grid.options?.api.forEachNode((rowNode) -> rowNode.setRowHeight(cellHeight))
                    scope.grid.options?.api.onRowHeightChanged()
                    # wait for the grid to change row height and then remove overlay
                    $timeout((->
                        scope.grid.options?.api?.hideOverlay()
                        unWatchGridUpdate()
                    ), 500) if options?.hideOverlay
                    return
            ))


        updateWithEmptyData = ->
            scope.view.rowsData = []
            scope.grid.updateAllData(scope.model, [])
            scope.grid.options?.api?.showNoRowsOverlay()
            scope.pageCount = 0
            return

        updateColumnsAndRowsData = (data) ->
            scope.view.rowsData = _.cloneDeep(data)
            scope.grid.updateAllData(scope.model, data)
            scope.grid.options?.api?.showLoadingOverlay()
            updateGridRowHeight({ hideOverlay: true })
            scope.pageCount = null
            return

        updateCellDisplayedData = ->
            return if not scope.model?.selected
            isDifferentOrder = not scope.model.selected.metrics.every((metricId, index) -> scope.view.selectedMetrics[index] is metricId)
            isDifferentLength = scope.model.selected?.metrics.length isnt scope.view.selectedMetrics.length
            scope.grid.updateCellRenderers(scope.model) if isDifferentLength or isDifferentOrder
            return

        updatePageCount = (data) ->
            model = scope.model
            if not model
                scope.pageCount = undefined
                return
            # Do this in the next tick, because it's possibly an expensive operation
            $timeout((-> scope.pageCount = do ->
                return if not model
                return (try getPageCountEstimate(model, data)) or 'unknown'
            ), 0)
            return

        updateData = (data) ->
            data ?= []
            data = do ->
                return data if Array.isArray(data)
                # https://sentry.io/organizations/42/issues/2700544907/?project=5685475&query=is%3Aunresolved
                Analytics.logError(throw new Error("Unexpected data format updating Data in Items Page")) if not _.isNil(data)
                return []
            do ->
                return updateWithEmptyData() if data.length is 0
                return updateCellDisplayedData() if _.isEqual(scope.view.rowsData, data)
                return updateColumnsAndRowsData(data)
            scope.view.selectedMetrics = do ->
                metrics = scope.model?.selected?.metrics
                return _.cloneDeep(metrics ? [])
            updatePageCount(data)
            updateGridRowHeight({ hideOverlay: true })
            return

        # We do a debounce here because when 'model' changes, 'model.selected' also
        # changes, and we don't want to do two refreshes simultaneously
        refresh = _.debounce (->
            scope.grid.setError(false)
            return scope.grid.clear() if not scope.model
            restrictItemsSortBy()
            scope.grid.updateGridToLoadingState(scope.model)
            scope.grid.options?.api?.showLoadingOverlay()
            try
                scope.gridDataModel = new ItemGridDataViewModel(scope.model, ItemGridQuery)
            catch error
                Analytics.logError(new Error('[grid data] [refresh] error'), {cause: error})
                scope.grid.setError(true)
                return
        ), 10

        scope.$watch 'gridDataModel.data', (data) ->
            return if not data
            updateData(data)
            return

        scope.$watch 'model', ->
            return if not scope.model
            refresh()
            return

        scope.$watch 'model.selected', (->
            return if not scope.model
            scope.model.save()
            refresh()
        ), true

        scope.$watch 'model.name', _.debounce(->
            return if not scope.model
            scope.model.save()
            return
        , 600), true

        ###* @param {boolean} imagesEnabled ###
        toggleImages = (imagesEnabled) ->
            return scope.grid.clear() if not scope.model
            restrictItemsSortBy()
            scope.grid.toggleImages(imagesEnabled)
            scope.grid.options?.api.refreshCells()
            updateGridRowHeight({ hideOverlay: false })
            return

        scope.$watch 'model.views.images.state', (state) ->
            return if not scope.model
            scope.model.save()
            toggleImages(state?.isOpen)
            return
]

module.constant 'ITEM_INDEPENDENT_PROPERTIES', [
    'items.variant_option_size'
    'items.size'
    'items.variant_option_color'
    'items.color_no'
    'items.color_code'
    'items.color'
    'items.season'
    'items.season_code'
    'items.season_name'
    'items.vendor'
    'items.gender'

    'items.live_on_site'
    'items.published_at_aging_bucket'

    # marolina
    'items.current_season'
    'items.original_season'
    'items.season_status'
]


module.directive 'itemNaturalLanguageQuery', ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <article class="item-natural-language-query" ng-show="model">
        <span>Showing the</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">top {{ model.selected.itemsLimitBy }}</span>
        <span>best</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy > 1">{{ model.selected.itemsGroupBy.plural || model.selected.itemsGroupBy.label }}</span>
        <span class="selected" ng-if="model.selected.itemsLimitBy <= 1">{{ model.selected.itemsGroupBy.label }}</span>
        <span>by</span>
        <span class="selected">{{ model.selected.itemsSortBy.label }}</span>
        <span ng-if="model.selected.groupBy.id != 'stores.company'">for each</span>
        <span ng-if="model.selected.groupBy.id != 'stores.company'" class="selected">{{ model.selected.groupBy.label }}</span>
    </article>
    """


module.factory 'ItemGridModel', ['$filter', ($filter) ->
    rowsOverlay = GridOverlayMessageRendererFactory()

    ###*
    @param {any} actionsModel
    @param {{items: unknown[]}[]} data
    @returns {{headerName: number | string, cellRenderer?: unknown, pinned?: string}[]}
    ###
    generateColumnDefs = (actionsModel, data) ->
        GridItemCellRenderer = GridItemCellRendererFactory($filter, actionsModel)
        GridInfoCellRenderer = GridInfoCellRendererFactory($filter, actionsModel)
        imageColumnCount = _.max(data.map (x) -> x.items.length)
        return [
            {
                headerName: actionsModel.selected.groupBy.label
                cellRenderer: GridInfoCellRenderer
                pinned: 'left'
            },
            ...(_.range(imageColumnCount ? 0).map((x, index) -> ({
                headerName: index+1,
                cellRenderer: GridItemCellRenderer
            })))
        ]

    return () ->
        data: null,
        columnDefs: [],
        clear: -> @update()

        updateAllData: (actionsModel, data) ->
            @data = data
            @columnDefs = do ->
                return [] if not data or data.length is 0
                return generateColumnDefs(actionsModel, data)
            @options.api.setRowData(data)
            @options.api.setColumnDefs(@columnDefs)

        updateGridToLoadingState: (actionsModel, data) ->
            columnMockData = (data ? @data ? []).map((x) -> { items : Array.from({length: x.items.length})})
            @columnDefs = generateColumnDefs(actionsModel, columnMockData)
            # @options.api.setRowData(data ? @data)
            @options.api.setColumnDefs(@columnDefs)

        updateCellRenderers: (actionsModel) ->
            @options.api.getCellRendererInstances().forEach (cellRenderer) ->
                cellRenderer.update(_.cloneDeep(actionsModel.selected?.metrics))

        toggleImages: (imagesEnabled) ->
            @options.api.getCellRendererInstances().forEach (cellRenderer) ->
                cellRenderer.toggleImages?(imagesEnabled)

        setError: (error) ->
            if error
                rowsOverlay.setError(true)
                @options.api.hideOverlay()
                @options.api.showNoRowsOverlay()
            else
                rowsOverlay.setError(false)
                @options.api.hideOverlay()

        rowsOverlay: rowsOverlay
        options:
            defaultColDef:
                resizable: false
                suppressMovable: true
                suppressMenu: true
                sortable: false
                filter: false
            rowBuffer: 10
            rowData: []
            rowHeight: 185
            headerHeight: 35
            colWidth: 200
            sortingOrder: ['desc','asc',null]
            localeText:
                loadingOoo: ' '
            noRowsOverlayComponent: rowsOverlay.component,
]


_ItemActionModelList = ['$q', 'ItemActionsModelState', 'ItemActionModel',
###*
@param {angular.IQService} $q
@param {ItemActionsModelStateService} ItemActionsModelState
@param {GridActionsModel} ItemActionModel
###
($q, ItemActionsModelState, ItemActionModel) -> return class ItemActionModelList

    ###* @param {unknown} state ###
    constructor: (state) ->
        {selected, available} = state
        @available = available.map (x) => new ItemActionModel(@save, x)
        @selected = _.find @available, (x) -> x.id is (selected.id or selected)
        @selected ?= @available[0]

    add: =>
        ItemActionsModelState.createState().then (state) =>
            model = new ItemActionModel(@save, state)
            @available.push(model)
            @selected = model
        @save()

    duplicate: (id) =>
        elementToDuplicate = @available.find (x) -> x.id is id
        ItemActionsModelState.duplicate(elementToDuplicate).then (newItem) =>
            model = new ItemActionModel(@save, newItem)
            @available.push(model)
            @selected = model
        @save()

    remove: (id) =>
        @available = @available.filter (x) -> x.id isnt id
        @selected = @available[0] or @selected
        @save()

    createTabFromJSON: (viewConfig) =>
        delete viewConfig.id
        viewConfig.name = "#{viewConfig.name} (shared)"
        ItemActionsModelState.createState(viewConfig).then (state) =>
            model = new ItemActionModel(@save, state)
            @available.push(model)
            @selected = model

    @ValidateViewConfigFile = (payload, orgId) ->
        try
            payload = JSON.parse(payload) if typeof payload is 'string'
        catch error
            console.error(error)
            throw new Error("View config import error: bad json.", {cause:error})
        if not isObject(payload)
            throw new Error("View config import error: must be a string or object")

        meta = GridPageViewMetaSchema.safeParse(payload.meta)
        if not meta.success
            console.error(meta.error)
            throw new Error('View config import error: meta field missing or invalid.')

        if meta.data.organizationId isnt orgId
            throw new Error('View config import error: incorrect organization.')

        parsedData = GridPageViewDataSchema.safeParse(payload.data)
        if not parsedData.success
            console.error(parsedData.error)
            throw new Error('Grid Page View config import error: data field missing or invalid.')

        return parsedData.data


    exportTab: () => $q.when do =>
        { available, selected } = ItemActionsModelState.serializeStates(@)
        data = _.find available, (x) -> x.id is selected
        downloadFile({
            data
            name: data.name
            type: 'tab-grid'
            namespace: 'grid'
            analyticsEvent: Analytics.EVENTS.USER_EXPORT_GRID_TAB
        })

    save: =>
        try return ItemActionsModelState.save(@)
        catch error then console.error(error)

    reorder: (oldIndex, newIndex) =>
        @available = Utils.Array.move(@available, oldIndex, newIndex)
        @selected = @available[newIndex]
        @save()

    toggleOffAllEditModes: (exclude) =>
        toToggleOff = if exclude then @available.filter((x) -> exclude isnt x) else @available
        toToggleOff.forEach (other) ->
            other.fillNameIfNeeded()
            other.editMode = false
            other.dropdown = false
]
module.factory('ItemActionModelList', _ItemActionModelList)


module.factory 'ItemActionModel', ['$rootScope','QueryMetrics','ITEM_INDEPENDENT_PROPERTIES',
###*
@param {DashboardRootScope} $rootScope
@param {IQueryMetrics} QueryMetrics
@param {string[]} ITEM_INDEPENDENT_PROPERTIES
###
($rootScope, QueryMetrics, ITEM_INDEPENDENT_PROPERTIES) -> return class ItemActionModel

    ###*
    @param {(...args: any) => any} parentSave
    @param {{
        id: string;
        name: string;
        selected: IGridActionsModel['selected'];
        values: IGridActionsModel['values'];
        views: {
            panel?: boolean | null;
            images?: boolean | null;
        };
    }} state
    ###
    constructor: (@parentSave, state) ->
        throw new Error("Missing required `values` property.") if not state?.values
        {@id, @name, values, views} = state
        @fillNameIfNeeded()
        @editMode = false
        @dropdown = false

        ###* @type {IGridActionsModel['values']} values ###
        @values = values

        ###* @type {IGridActionsModel['selected']} selected ###
        @selected = {
            ...(state.selected ? {}),
            groupBy        : state.selected?.groupBy        ? values.groupBy[0],
            itemsGroupBy   : state.selected?.itemsGroupBy   ? values.itemsGroupBy[0],
            itemsSortBy    : state.selected?.itemsSortBy    ? values.itemsSortBy[0],
            itemsLimitBy   : state.selected?.itemsLimitBy   ? 15,
            itemsSortOrder : state.selected?.itemsSortOrder ? -1,
            itemsImageSize : state.selected?.itemsImageSize ? 0,
            itemsExtraInfo : []
        }

        ###* @type {IGridActionsModel['views']} views ###
        @views =
            metrics : new ToggleModel(false)
            panel   : ToggleModel.Deserialize(views?.panel, true)
            images  : ToggleModel.Deserialize(views?.images, true)

        @updateExtraItemInfo()
        return


    fillNameIfNeeded: ->
        @name = if @name.length is 0 then "New View" else @name
        return

    ###* @returns {boolean} ###
    toggleTabNameEditor: ->
        @fillNameIfNeeded()
        @editMode = not @editMode

    ###* @returns {boolean} ###
    toggleDropdown: ->
        @fillNameIfNeeded()
        @dropdown = not @dropdown

    ###* @returns {IPropertyDefinition[]} ###
    updateExtraItemInfo: ->
        @selected.itemsExtraInfo = @_getExtraItemInfo()
        return @selected.itemsExtraInfo

    ###* @param {(IMetricDefinition | string)[]} metrics ###
    setSelectedMetrics: (metrics) ->
        fields = metrics.map((x) -> if typeof x is 'string' then x else x.field)
        fields = Utils.Object.pickValues(@values.metricsByField, fields).map((x) -> x.field)
        @selected.metrics = fields
        return

    ###* @returns {IPropertyDefinition[]} ###
    _getExtraItemInfo: ->
        selectedGroupBy = @selected.itemsGroupBy
        availableGroupBy = @values?.itemsGroupBy
        return [] if not selectedGroupBy
        return [] if not availableGroupBy
        return [] if selectedGroupBy.table isnt 'items'
        return [] if ITEM_INDEPENDENT_PROPERTIES.includes(selectedGroupBy.id)

        # This function defines which extra properties should be listed with the item.
        ###* @param {IPropertyDefinition} property ###
        extraPropertyShouldBeShown = (property) ->
            isValidField = ['items.name', 'items.product_name', 'items.item_description'].includes(property.id)
            isNotSelected = property.id isnt selectedGroupBy?.id
            return isValidField and isNotSelected

        extraItemProperties = Utils.copy(availableGroupBy ? []).filter(extraPropertyShouldBeShown)
        selectionPosition = availableGroupBy.findIndex((x) -> selectedGroupBy?.id is x.id)
        ###* @type {IPropertyDefinition[]} result ###
        result = []
        return extraItemProperties.reduce(((result, property) ->
            propertyPosition = availableGroupBy?.findIndex((x) -> property.id is x.id) ? -1
            return result if selectionPosition < propertyPosition or propertyPosition is -1
            return [...result, property]
        ), result)

    ###* @returns {Promise<void>} ###
    save: =>
        return @parentSave()

    toQuery: (baseQuery = null) ->
        query = Utils.copy(baseQuery ? $rootScope.query ? {})
        query.options =
            groupBy:      @selected.groupBy.id
            itemsGroupBy: @selected.itemsGroupBy.id
            itemsSortBy:  @selected.itemsSortBy.id
            itemsLimitBy: @selected.itemsLimitBy
            itemsSortOrder: @selected.itemsSortOrder ? -1
        delete query.sort
        return QueryMetrics.fetch().then((metrics) -> ({
            ...query,
            options: {...query.options, metrics: metrics.map((metric) -> metric.field)}
        }))

    toExportQuery: ->
        queryExport = Utils.copy do =>
            values: @values
            selected: @selected
            options: ITEM_EXPORT_OPTIONS
        return @toQuery().then (query) ->
            query.type = "pdf"
            query.export = queryExport
            return query
]
